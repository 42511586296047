import { Model } from "@vuex-orm/core";

export class MSchool extends Model {
    static get entity() {
        return "m_schools"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "m_school_name": this.string(""),
            "create_by": this.attr(null),
            "update_by": this.attr(null),
            "createdAt": this.attr(null),
            "updatedAt": this.attr(null),
            "mProvinceId": this.attr(null),
            "mAmphurId": this.attr(null),
            "mHeadquarterId": this.attr(null),
            "mDivisionId": this.attr(null),
            "mStationId": this.attr(null)
        }
    }

    static get apiConfig() {
        return {
            actions: {
                findAll(params = {}, props = {}) {
                    return this.get('/m_school', { params }, props);
                },

                create(body) {
                    return this.post('/m_school', body);
                },

                update(id, body) {
                    return this.put(`/m_school/${id}`, body);
                },

                destroy(id) {
                    return this.delete(`/m_school/${id}`, { delete: id });
                }
            }
        };
    }
}