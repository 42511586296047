import { Model } from "@vuex-orm/core";

export class MAmphur extends Model {
    static get entity() {
        return "m_amphurs"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "m_amphur_name": this.string(""),
            "mProvinceId": this.attr(null),
            "create_by": this.attr(null),
            "update_by": this.attr(null),
            "createdAt": this.attr(null),
            "updatedAt": this.attr(null),
        }
    }

    static get apiConfig() {
        return {
            actions: {
                findAll(params = {}) {
                    return this.get('/m_amphur', { params });
                }
            }
        };
    }
}