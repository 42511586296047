import { Model } from "@vuex-orm/core";

export class TeachingActual extends Model {
    static get entity() {
        return "teaching_actuals"
    }

    static fields() {
        return {

        }
    }

    static get apiConfig() {
        return {
            actions: {
                findAll(params = {}) {
                    return this.get('/teaching_actual', { params });
                }
            }
        };
    }
}