<template>
  <div id="app" class="d-flex flex-fill">
    <component :is="layout">
      <router-view v-if="ready" />

      <div v-else style="width: 250px;" class="text-center">
        <b-progress
          class="mb-3 progress-bar-animated-alt"
          :value="100"
          :max="100"
          height="0.5rem"
        ></b-progress>

        <p class="text-black-50">
          {{ message }}
        </p>
      </div>
    </component>
  </div>
</template>

<script>
import { Auth } from "./models";

const default_layout = "default";

export default {
  data() {
    return {
      ready: false,
      message: "",
    };
  },

  computed: {
    layout() {
      return this.ready
        ? (this.$route.meta.layout || default_layout) + "-layout"
        : "no-layout";
    },

    page() {
      return this.$route.name;
    },
  },

  methods: {
    init() {
      this.message = "กำลังตรวจสอบข้อมูล";

      setTimeout(async () => {
        if (this.$route.meta.auth) {
          const authToken = Auth.getAuthToken();
          const authId = Auth.getAuthId();

          if (authToken && authId) {
            this.message = "โหลดข้อมูลผู้ใช้งาน";

            await Auth.load();

            this.ready = true;
          }
        } else {
          this.ready = true;
        }
      }, 1000);
    },
  },

  created() {
    const unwatch = this.$watch(
      () => this.$route,
      () => {
        this.init();
        unwatch();
      }
    );
  },
};
</script>

<style lang="scss">
@import "./assets/scss/index";
</style>
