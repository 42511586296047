<template>
  <!-- <div
    class="app-wrapper"
    v-bind:class="{
      'sidebar-collapsed': sidebarCollapsed,
      'sidebar-open-mobile': sidebarCollapsedMobile,
    }"
  >
    <Sidebar :sidebarbg="sidebarcolor" />

    <div
      class="sidebar-mobile-overlay"
      v-on:click="toggleSidebarMobile"
      v-show="sidebarCollapsedMobile"
    ></div> -->

  <div class="app-main">
    <transition name="fade" mode="out-in" appear>
      <Header show-sidebar show-auth />
    </transition>

    <div class="app-content">
      <div>
        <slot />
      </div>
      <transition name="fade" mode="out-in" appear>
        <Footer />
      </transition>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import Header from "../../components/layout/Header";
// import Sidebar from "../../components/layout/Sidebar";
import Footer from "../../components/layout/Footer";

export default {
  components: {
    Header,
    // Sidebar,
    Footer,
  },
  data() {
    return {
      sidebarcolor: "app-sidebar--light",
    };
  },
  computed: {
    sidebarCollapsed: {
      get() {
        return this.$store.state.sidebarCollapsed;
      },
    },
    sidebarCollapsedMobile: {
      get() {
        return this.$store.state.sidebarCollapsedMobile;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED_MOBILE", value);
      },
    },
  },
  methods: {
    toggleSidebarMobile() {
      this.sidebarCollapsedMobile = !this.sidebarCollapsedMobile;
    },
  },
};
</script>
