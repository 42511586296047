import { Model } from "@vuex-orm/core";

export class Banner extends Model {
    static get entity() {
        return "banners"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "banner_titile": this.string(""),
            "keyImg": this.attr(null),
            "create_by": this.attr(null),
            "update_by": this.attr(null),
            "createdAt": this.attr(null),
            "updatedAt": this.attr(null),
        }
    }

    static get apiConfig() {
        return {
            actions: {
                findAll(params = {}) {
                    return this.get('/banner', { params });
                },

                create(body) {
                    return this.post('/banner', body);
                },

                update(id, body) {
                    return this.put(`/banner/${id}`, body);
                },

                destroy(id) {
                    return this.delete(`/banner/${id}`, { delete: id });
                }
            }
        };
    }
}