import { Model } from "@vuex-orm/core";

export class StudentDare extends Model {
    static get entity() {
        return "student_dares"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "first_name": this.string(""),
            "last_name": this.string(""),
            "id_card": this.string(""),
            "phone_number": this.string(""),
            "email": this.string(""),
            "create_by": this.attr(null),
            "update_by": this.attr(null),
            "createdAt": this.attr(null),
            "updatedAt": this.attr(null),
            "teachingDareId": this.attr(null),
            "mPrefixId": this.attr(null),
            "mCardtypeId": this.attr(null)
        }
    }

    static get apiConfig() {
        return {
            actions: {
                findAll(params = {}) {
                    return this.get('/student_dare', { params });
                },

                async create(form) {
                    let promise;

                    promise = await this.post(`/student_dare`, {
                        ...form
                    });

                    const { response_status, response_description, data } = promise.response.data;

                    if (response_status === "ERROR") {
                        throw response_description;
                    }

                    const { student_dare } = data;

                    StudentDare.insert({
                        data: { ...student_dare }
                    });

                    return promise;
                },

                async update(id, form) {
                    return this.put(`/student_dare/${id}`, {
                        ...form
                    });
                },

                destroy(id) {
                    return this.delete(`/student_dare/${id}`, { delete: id });
                }
            }
        };
    }
}