import { Model } from "@vuex-orm/core";
import { QuestionAnswer } from "./QuestionAnswer";
export class QuestionChoice extends Model {
    static get entity() {
        return "question_choices"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "question_choice_no": this.string(""),
            "question_choice_title": this.attr(null),
            "created_by": this.attr(null),
            "update_by": this.attr(null),
            "createdAt": this.attr(null),
            "updatedAt": this.attr(null),
            "questionId": this.attr(null),
            "question_answers": this.hasMany(QuestionAnswer, 'questionChoiceId'),
        }
    }

    static get apiConfig() {
        return {
            actions: {
            }
        };
    }
}