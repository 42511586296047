import { Model } from "@vuex-orm/core";

export class TeachingActualImg extends Model {
    static get entity() {
        return "teaching_actual_imgs"
    }

    static fields() {
        return {

        }
    }

    static get apiConfig() {
        return {
            actions: {

            }
        };
    }
}