import { Model } from "@vuex-orm/core";

export class TeacherDare extends Model {
    static get entity() {
        return "teacher_dares"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "first_name": this.string(""),
            "last_name": this.string(""),
            "id_card": this.string(""),
            "phone_number": this.string(""),
            "email": this.string(""),
            "create_by": this.attr(null),
            "update_by": this.attr(null),
            "createdAt": this.attr(null),
            "updatedAt": this.attr(null),
            "teachingDareId": this.attr(null),
            "mPrefixId": this.attr(null),
            "mCardtypeId": this.attr(null)
        }
    }

    static get apiConfig() {
        return {
            actions: {
                findAll(params = {}) {
                    return this.get('/teacher_dare', { params });
                },
            }
        };
    }
}