import { Model } from "@vuex-orm/core";
import { QuestionAnswer } from "./QuestionAnswer";
import { QuestionChoice } from "./QuestionChoice";
export class Question extends Model {
    static get entity() {
        return "questions"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "question_no": this.string(""),
            "question_title": this.attr(null),
            "question_type": this.string(""),
            "created_by": this.attr(null),
            "update_by": this.attr(null),
            "createdAt": this.attr(null),
            "updatedAt": this.attr(null),
            "surveyId": this.attr(null),
            "surveyTopicId": this.attr(null),
            "question_answers": this.hasMany(QuestionAnswer, 'questionId'),
            "question_choices": this.hasMany(QuestionChoice, 'questionId')
        }
    }

    static get apiConfig() {
        return {
            actions: {
                findAll(params = {}) {
                    return this.get('/question', { params });
                },
            }
        };
    }
}