import { Model } from "@vuex-orm/core";

export class QuestionAnswer extends Model {
    static get entity() {
        return "question_answers"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "answer": this.string(""),
            "choice_answer": this.attr(null),
            "text_answer": this.string(""),
            "rating_answer": this.attr(null),
            "created_by": this.attr(null),
            "update_by": this.attr(null),
            "createdAt": this.attr(null),
            "updatedAt": this.attr(null),
            "surveyId": this.attr(null),
            "questionId": this.attr(null),
            "questionChoiceId": this.attr(null),
        }
    }

    static get apiConfig() {
        return {
            actions: {
            }
        };
    }
}