import { Model } from "@vuex-orm/core";
import { Question } from "./Question";

export class SurveyTopic extends Model {
    static get entity() {
        return "survey-topics"
    }

    static fields() {
        return {
            "id": this.attr(null),
            "survey_topic_no": this.string(""),
            "survey_topic_title": this.attr(null),
            "description": this.string(""),
            "created_by": this.attr(null),
            "update_by": this.attr(null),
            "createdAt": this.attr(null),
            "updatedAt": this.attr(null),
            "surveyId": this.attr(null),
            "question_ids": this.attr(null),
            "questions": this.hasManyBy(Question, 'question_ids', 'surveyTopicId')
        }
    }

    static get apiConfig() {
        return {
            actions: {

            }
        };
    }
}