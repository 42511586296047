<template>
  <div
    class="app-header"
    style="background-image: url('./assets/img/header-bg.png');"
  >
    <div class="d-flex">
      <!-- <button
        v-if="showSidebar"
        class="navbar-toggler hamburger hamburger--elastic toggle-sidebar"
        v-bind:class="{ 'is-active': sidebarCollapsed }"
        type="button"
        v-on:click="toggleSidebar"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>

      <button
        v-if="showSidebar"
        class="
          navbar-toggler
          hamburger hamburger--elastic
          toggle-sidebar-mobile
        "
        v-bind:class="{ 'is-active': sidebarCollapsedMobile }"
        type="button"
        v-on:click="toggleSidebarMobile"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button> -->

      <!-- <div class="nav-logo text-center ml-3" >
        <router-link
          class="d-block"
          active-class="active"
          to="/"
          exact
          v-b-tooltip.hover
        >
          <p>แจ้งเบาะแสการกระทำผิด</p>
        </router-link>
      </div> -->
      <!-- <div class="search-link">
                <div class="d-none d-lg-flex align-items-center">
                    <font-awesome-icon icon="search" class="search-icon w-auto" />
                    <input type="text" placeholder="Click here to search...">
                </div>
                <div class="d-none d-sm-flex d-lg-none">
                    <b-button variant="outline-primary" size="sm" class="px-3" v-b-modal.search-modal>
                        <font-awesome-icon icon="search" />
                    </b-button>
                </div>
            </div> -->
    </div>

    <div class="d-flex align-items-center">
      <b-button variant="link" target="_blank" href="https://drive.google.com/file/d/1aZ0rQeUZOcC89LR8Jl_sVqA1la0IzeF3/view?usp=sharing"
        >คู่มือการใช้งานระบบ</b-button
      >
      <button class="btn btn-m btn-primary  " @click="showModalCheck = true">
        <span>
          ติดตามผลการแจ้งเบาะแส
        </span>
      </button>
      
      <b-button v-if="showAuth && authUser" variant="link" target="_blank"
        >แจ้งปัญหา</b-button
      >
      <b-button v-if="showAuth && authUser" variant="link" target="_blank"
        >คำถามที่พบบ่อย</b-button
      >
      <b-button v-if="showAuth && authUser" variant="link" target="_blank"
        >คู่มือการใช้งานระบบ</b-button
      >
      <sign-out v-if="showAuth && authUser" />
    </div>

    <!-- <b-modal id="search-modal" centered>
            <div slot="modal-header">
                <h6 class="display-4 font-weight-bold">Search</h6>
                <p class="text-black-50 mb-0">Use the form below to search for files</p>
            </div>
            <div class="p-5">
                <div class="input-group">
                    <input type="search" class="form-control" placeholder="Search terms here..." />
                    <div class="input-group-append">
                        <b-button variant="primary" class="border-0">
                            <font-awesome-icon icon="search" />
                        </b-button>
                    </div>
                </div>
            </div>
            <div slot="modal-footer" class="d-block w-100">
                <b-button variant="link" block class="btn-link-dark" @click="$bvModal.hide('search-modal')">Close search box</b-button>
            </div>
        </b-modal> -->
    <b-modal
      v-model="showModalCheck"
      id="doc-modal"
      size="xl"
      centered
      hide-footer
      hide-header
    >
      <!-- <div slot="modal-header">
                <h6 class="display-4 font-weight-bold">Search</h6>
                <p class="text-black-50 mb-0">
                  Use the form below to search for files
                </p>
              </div> -->
      <b-container>
        <b-row class="flex-sm-column-reverse flex-md-row">
          <b-col lg="12" class="d-flex flex-column justify-content-between">
            <p
              class="
                        
                        text-center
                      "
            >
              ขอขอบคุณ<br />สำหรับการแจ้งเบาะแสของท่าน
            </p>
          </b-col>
          <b-col lg="12">
            <h5
              class="display-5 mb-2 text-primary font-weight-bold text-center"
            >
              กรุณากรอกหมายเลขการแจ้งเบาะแส<br>หรือเบอร์โทรศัพท์ที่แจ้งไว้ เพื่อค้นหาข้อมูล
            </h5>
            <input
              placeholder="หมายเลขการแจ้งเบาะแสที่ขึ้นต้นด้วย TDXXXXX หรือ เบอร์โทรศัพท์ของท่าน"
              type="text"
              class="form-control text-center"
              v-model="requestNo"
            />
            <!-- <p class="text-center" style="font-size: 0.7rem;">
                      *กรุณาจดบันทึกหมายเลขร้องเรียนนี้ไว้
                      เพื่อใช้ในการตรวจสอบความคืบหน้า
                    </p> -->
            <br />
          </b-col>
          <button class="btn btn-lg btn-primary btn-block" @click="onClickSearch()">
            ตรวจสอบ
          </button>
          
          <b-col lg="12" v-if="requestStatus">
            <br>
            <br>
            <p
              class="display-5 mb-2 text-primary font-weight-bold text-center"
            >
              สถานะการแจ้งเบาะแสของท่าน: <br> <br> <span style="font-size: 1.3rem;"><b>{{requestStatus.status}}</b></span>
            </p>
            <br>
            <p
              class="display-5 mb-2 text-primary font-weight-bold text-center"
            >
              รายละเอียดเพิ่มเติมจากเจ้าหน้าที่: <br> <br> <span style="font-size: 1.3rem;"><b>{{requestStatus.admin_comment || '-'}}</b></span>
            </p>
            <!-- <p class="text-center" style="font-size: 0.7rem;">
                      เจ้าหน้าที่จะติดต่อหาท่านตาม
                    </p> -->
            <br />
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import SignOut from "../header/SignOut";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Auth } from "../../models";
import axios from "axios";

library.add(faSearch);

export default {
  props: {
    showSidebar: Boolean,
    showAuth: Boolean,
  },

  components: {
    SignOut,
  },

  data() {
    return {
      showModalCheck: false,
      requestNo: "",
      requestStatus: null
    };
  },

  computed: {
    sidebarCollapsed: {
      get() {
        return this.$store.state.sidebarCollapsed;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED", value);
      },
    },
    sidebarCollapsedMobile: {
      get() {
        return this.$store.state.sidebarCollapsedMobile;
      },
      set(value) {
        this.$store.commit("SET_SIDEBAR_COLLAPSED_MOBILE", value);
      },
    },

    authUser() {
      return Auth.getAuthId();
    },
  },
  methods: {
    myReason() {
      location.href = "/my-reason";
    },
    home() {
      location.href = "/";
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    toggleSidebarMobile() {
      this.sidebarCollapsedMobile = !this.sidebarCollapsedMobile;
    },
    async onClickSearch() {
      console.log(this.requestNo);

      var res = await axios.get(
        `https://thai-drug-service-temp.yuzudigital.com/reportForms/checkStatus/${this.requestNo}`,
        // `http://localhost:8081/reportForms/checkStatus/${this.requestNo}`,
        this.reportInformation
      );

      if (res.data.response_status == "SUCCESS") {
        this.requestStatus = res.data.data;
      } else {
        this.$toast.error(res.data.message);
      }
    },
  },
};
</script>
